import React, {useState} from "react";
import {Link} from 'gatsby'
import "@fontsource/roboto-condensed"

import "../styles/layout.css";
import styles from "./layout2.module.css";
import logorec from '../images/logorec.svg';
import logo from '../images/logo.svg';

import Lottie from 'react-lottie';
import animationData from '../animations/menu.json';
import imgBot from '../images/imgnav.png';

import logoFooter from '../images/logofooter.png'
import soundcloud from '../images/soundcloud.svg'
import applemusic from '../images/applemusic.svg'
import spotify from '../images/spotify.svg'
import youtube from '../images/youtube.svg'
import facebook from '../images/facebook.svg'
import instagram from '../images/instagram.svg'


const Layout2 = ({ children}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [animationState, setAnimationState] = useState({ 
    isStopped: true, isPaused: false,
    direction: -1,
  });

  const defaultOptions = {
    loop: false,
    autoplay: false, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return(
  <div className={isOpen ? styles.menuopened : styles.menuclosed}>
    <div className={styles.menu}>
        <label for="toggle"
          className={styles.button}
          onClick={() => {
            const reverseAnimation = -1;
            const normalAnimation = 1;

            setAnimationState({
              ...animationState,
              isStopped: false,
              direction: animationState.direction === normalAnimation 
                ? reverseAnimation
                : normalAnimation,
            }),
            setIsOpen(!isOpen)
          }}>
          <Lottie options={defaultOptions}
            height={56}
            width={56}
            direction={animationState.direction}
            isStopped={animationState.isStopped}
            isPaused={animationState.isPaused}
          />
        </label>
        <input type="checkbox" id="toggle"/>
        <div className={styles.mobilemenu}>
          <div className={styles.mobileLogo}>
            <Link className to='/'><img src={logo} alt="As Ganhadeiras de Itapuã - Museu Virtual Casa de Ganho"/></Link>
          </div>
          <div className={styles.links}>
            <Link to='/'>página inicial</Link>
            <Link to='/p/sobre-o-grupo/'>sobre o grupo</Link>
            <Link to='/timeline/'>linha do tempo</Link>
            <Link to='/contato/'>contato</Link>
            <a href="https://loja.ganhadeirasdeitapua.org/" target="_blank" rel="noopener noreferrer">Loja</a>
            <Link to='/p/apoio-financeiro/'>apoio financeiro</Link>
          </div>
          <div className={styles.mobileBot}>
            <img src={imgBot} alt="As Ganhadeiras de Itapuã - Museu Virtual Casa de Ganho"/>
          </div>
        </div>
      </div>
    <div/>

    <div className={styles.logo}>
      <Link to='/'><img src={logorec} alt="As Ganhadeiras de Itapuã - Museu Virtual Casa de Ganho" width="144" height="130"/></Link>
    </div>
     
    <div className={styles.content}>{children}</div>
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerLeft}>
          <div className={styles.footerMenu}>
            <Link to={'/p/sobre-o-grupo/'}>Sobre o grupo</Link>
            <Link to={'/timeline/'}>Linha do tempo</Link>
            <Link to={'/recepcao/'}>Museu virtual</Link>
            <Link to={'/contato/'}>Contato</Link>
            <a href="https://loja.ganhadeirasdeitapua.org/" target="_blank" rel="noopener noreferrer">Loja</a>
            <Link to='/p/apoio-financeiro/'>Apoio financeiro</Link>
            <Link to='/p/creditos-site/'>Créditos do museu</Link>
          </div>
        </div>
        <div className={styles.footerCenter}>
          <div className={styles.footerLogo}>
            <Link to={'/'}><img src={logoFooter} alt="As Ganhadeiras de Itapuã"/></Link>
          </div>
        </div>
        <div className={styles.footerRight}>
          <p>Vem com a gente!</p>
          <div className={styles.footerSocial}>
            <a href="https://soundcloud.com/asganhadeirasdeitapua/sets/as-ganhadeiras-de-itapua-1" target="_blank" rel="noopener noreferrer"><img src={soundcloud} alt="SoundCloud"/></a>
            <a href="https://music.apple.com/br/artist/as-ganhadeiras-de-itapu%C3%A3/946336972" target="_blank" rel="noopener noreferrer"><img src={applemusic} alt="Apple Music"/></a>
            <a href="https://open.spotify.com/artist/6RvtIoZAX7XyMEdd5WlG2L" target="_blank" rel="noopener noreferrer"><img src={spotify} alt="Spotify"/></a>
            <a href="https://www.youtube.com/user/ganhadeirasdeitapua" target="_blank" rel="noopener noreferrer"><img src={youtube} alt="YouTube"/></a>
            <a href="https://www.facebook.com/AsGanhadeirasdeItapua" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="Facebook"/></a>
            <a href="https://www.instagram.com/asganhadeirasdeitapua/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="Instagram"/></a>
          </div>
        </div>
      </div>
      <div className={styles.footerCorda}><p/></div>
      <div className={styles.footerEstampa}><p/></div>
      <div className={styles.footerCopyright}>
        <p className={styles.ganhadeiras}>2021 © Associação Cultural As Ganhadeiras de Itapuã</p> 
        <p className={styles.divider}> | </p>
        <p className={styles.solisluna}><Link to='/p/creditos-site/'>Site desenvolvido por Solisluna</Link></p>
      </div>
    </footer>
  </div>)
};

export default Layout2;
