import React from 'react'
import {Link} from 'gatsby'
import {Helmet} from "react-helmet";
import tutorial from "../images/tutorial.svg"
import vr from "../images/vr.svg"

import imgnav from "../images/imgnav.png"
import nextimg from '../images/next.svg'

import styles from './recepcao.module.css'

export default
class Recepcao extends React.Component {
  

render() {
    return (
        <div className={styles.root}>

          {/* <p className={styles.normaltext}>Clique no <strong>play</strong> para começar.</p> */}

          <div className={styles.frame}>
            <iframe src="https://fast.wistia.net/embed/iframe/b4ltryaz15?videoFoam=true" title="Museu Virtual Casa de Ganho Video" allow={"autoplay; fullscreen"}
            allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen msallowfullscreen width="100%" height="100%"></iframe>
            <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>
          </div>

          <p className={styles.setatext}><Link to="/museuvirtual"><strong>Pular vídeo de abertura</strong> <img src={nextimg} alt="Seta vermelha"/></Link></p>
          
          <div className={styles.blocks}>
            <Link to="" className={styles.navegar}>
              <div className={styles.block}>
                <div className={styles.content}>
                  <img src={tutorial} alt="Balão com interrogação"/>
                  <div className={styles.texts}>
                    <p className={styles.blocktitle}>Como navegar?</p>
                    <p className={styles.blocksub}>Clique aqui para aprender</p>
                  </div>
                </div>
              </div>
            </Link>

            <a href="https://www.thinglink.com/webvr/1435024917799108609" target="_blank">
              <div className={styles.block}>
                <div className={styles.content}>
                  <img src={vr} alt="Realidade virtual"/>
                  <div className={styles.texts}>
                    <p className={styles.blocktitle}>Modo realidade virtual</p>
                    <p className={styles.blocksub}>Clique aqui para acessar</p>
                  </div>
                </div>
              </div>
            </a>
          </div>

          <p className={styles.normaltext}><strong>Já visitou o museu?</strong></p>

          <p className={styles.normaltext}><a href="https://docs.google.com/forms/d/e/1FAIpQLSfkd0086V02gXyVLRkTakNx6hCKcoe86dQ7Ymfrm36KFO3A2Q/viewform" target="_blank" rel="noopener noreferrer"><strong>Conte-nos a sua opinião!</strong></a></p>

          <img className={styles.botrec} src={imgnav} alt="As Ganhadeiras de Itapuã"/>

        </div>
        );
    };
}

