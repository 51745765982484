import React from 'react'
import {graphql} from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout2 from '../components/layout2'
import Recepcao from '../components/recepcao'

export const query = graphql`
  query RecepcaoPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    } 
  }
`

const RecepcaoPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout2>
        <SEO
            title={"Recepção"}
            description={site.description}
            keywords={site.keywords}
        />
        <Recepcao />
    </Layout2>
  )
}

export default RecepcaoPage